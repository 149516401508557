@import '@/styles/styles';

.container {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 300px;
  position: relative;
  width: 270px;

  &.large {
    width: 570px;

    @include media-breakpoint-down(lg) {
      width: 270px;
    }
  }

  .box {
    align-items: flex-end;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    filter: drop-shadow(0px 10px 74px rgba(0, 0, 0, 0));
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: all 0.32s ease;
    border: 2px solid #f3f3f3;
    width: 100%;
  }

  .background {
    background-color: #3c3c3c;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 320ms;
    width: 100%;
    z-index: -1;

    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.67%, #000000 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .eventDetail {
    padding: 12px 20px 15px 20px;
    align-items: center;
    background-color: white;
    position: relative;
    display: grid;
    grid-template-columns: 16px auto;
    column-gap: 15px;
    row-gap: 7px;
    justify-items: center;
    .detailText {
      color: #012342;
      font-size: 14px;
      font-weight: 450;
      text-align: left;
      width: 100%;
    }
  }
  .content {
    margin-top: auto;
    transition: all 0.32s ease;
    width: 100%;
    .contentTop {
      padding: 20px 20px 0px 20px;
    }
    .rating {
      width: 120px;
    }
    .contentTitle {
      width: 100%;
      gap: 20px;
      padding-bottom: 12px;
    }
    .eventType {
      margin-bottom: 10px;
      padding-left: 20px;
      padding-top: 10px;
      background: #49a5ef;
      border-radius: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      opacity: 0;
      padding: 3.5px 12px;
      text-transform: uppercase;
      transition: all 0.32s ease;
      width: fit-content;
    }

    .name {
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      transition: all 0.32s ease;
      @include linesLimit(2);
    }

    svg {
      transition: all 0.32s ease;
    }
    .actions {
      column-gap: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: calc(-1 * var(--element-height, 0));
      margin-top: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.32s ease;

      button {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        white-space: nowrap;
      }

      :global {
        button {
          &.btn-primary,
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .follower {
      display: none;
    }
  }

  &:hover {
    z-index: 1;

    .box {
      filter: drop-shadow(0px 10px 74px rgba(0, 0, 0, 0.3));
      height: 468px;
      left: -41px;
      top: -102px;
      width: 382px;
    }

    .background {
      height: var(--hover-height, 0);
    }

    &.large {
      .box {
        @include media-breakpoint-up(lg) {
          left: -40px;
          width: 650px;
        }
      }
    }

    .content {
      .eventType {
        opacity: 1;
      }
      .name {
        color: #000000;
      }
      svg {
        color: #edc21e;
      }
      .actions {
        padding: 0px 30px 20px;
        margin-bottom: 0;
        margin-top: 23px;
        opacity: 1;
      }
    }
  }
}

.container.large {
  .eventDetail {
    background-color: transparent;
    display: grid;
    grid-template-columns: 14px minmax(auto, 150px) 14px auto;
    .detailText {
      color: white;
    }
    svg path {
      fill: white;
    }
  }
  &:hover {
    .eventDetail {
      .detailText {
        color: #012342;
      }
      svg path {
        fill: black;
      }
    }
  }
}

.container.default {
  .follower {
    display: grid;
    grid-template-columns: 14px auto;
    gap: 10px;
    padding: 4px 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    align-items: center;
  }
}
