@import '@/styles/styles';

.container {
  position: relative;
}

.mailing {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 27px 0;
  position: relative;
  column-gap: 10px;

  .background {
    background: linear-gradient(105.8deg, #51a7ed 0%, #12508a 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    @include fullWidth();
  }

  .left {
    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
    }

    .benefit {
      font-size: 16px;
      font-weight: 450;
      line-height: 20px;
    }
  }

  .right {
    column-gap: 10px;
    display: grid;
    grid-template-columns: minmax(auto, 290px) auto;

    .email {
      width: 100%;

      :global {
        .input-group {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 5px;

          .input-group-text {
            background-color: transparent;
            border: none;
            padding-right: 0;
          }

          input.form-control {
            background-color: transparent;
            border: none;
            color: #ffffff;
            padding: 0.5rem 0.75rem;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;

            &:focus {
              box-shadow: none;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
            }

            &::placeholder {
              color: #ffffff;
              opacity: 1; /* Firefox */
              font-weight: 450;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }

    button {
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    text-align: center;
    width: 100%;

    .left {
      margin-bottom: 30px;
      .title {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
      }

      .benefit {
        font-size: 16px;
        font-weight: 450;
        line-height: 20px;
      }
    }
  }
}

.signup {
  color: #ffffff;
  padding: 76px 0;
  position: relative;

  .background {
    background-image: url(https://swift-spar.s3.us-west-2.amazonaws.com/events-wiz/footer-1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    @include fullWidth();

    &::before {
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .content {
    .title {
      font-size: 48px;
      font-weight: 700;
      line-height: 61px;
      margin-bottom: 20px;
      max-width: 500px;
    }

    .description {
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 30px;
      max-width: 570px;
    }

    button {
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 14px 57px;
    }
  }

  @include media-breakpoint-down(md) {
    .content {
      .title {
        font-size: 36px;
        line-height: 46px;
        max-width: 100%;
      }
      .description {
        font-size: 16px;
        line-height: 21px;
        max-width: 100%;
      }
    }
  }
}
