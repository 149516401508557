@import '@/styles/styles';

.container {
  position: relative;
  max-width: 100vw;

  .title {
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 22px;
  }
}

.carousel {
  margin-left: calc(50% - 50vw);
  width: 100vw;
  overflow: hidden;

  .box {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include layout();
  }

  :global {
    .swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          width: auto;
        }
      }
    }
  }
}
