@import '@/styles/styles';

.container {
  position: relative;
  width: 100%;
  height: auto;
}

.background {
  position: relative;
  height: 0;
  padding-top: max(48%, 500px);
  max-height: 700px;
}

.image {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  margin-left: calc(50% - 50vw);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/main-image.png");
  z-index: -1;
  background-color: #3c3c3c;

  &::before {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.banner {
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  column-gap: 30px;

  .bannerBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    margin-left: calc(50% - 50vw);
    background: linear-gradient(105.8deg, #51a7ed 0%, #12508a 100%);
    z-index: -1;
  }

  button {
    color: #ffffff;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding: 13px 65px 12px 65px;
    white-space: nowrap;

    &:hover {
      color: #ffffff;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    button {
      margin-top: 30px;
    }
  }
}
