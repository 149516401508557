@import '@/styles/styles';

.container {
  align-items: flex-end;
  border-radius: 10px;
  display: flex;
  height: 300px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 270px;
}

.background {
  background-color: #3c3c3c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.67%, #000000 100%);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.content {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 12px;
  @include linesLimit(2);
}
