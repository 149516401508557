@import '@/styles/styles';

.container {
  position: relative;
  max-width: 100vw;
}

.carousel {
  margin-left: calc(50% - 50vw);
  width: 100vw;
  overflow: hidden;

  .box {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    @include layout();
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 0;
    width: 169px;

    .icon {
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      svg {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
      }
    }
    .name {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      @include linesLimit(2);
    }
  }

  :global {
    .swiper-button-disabled {
      svg {
        path {
          fill: $gray;
        }
      }
    }

    .swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          width: auto;
          z-index: 0;
          &:hover {
            z-index: 1;
          }
        }
      }
    }
  }
}
