@import '@/styles/styles';

.container {
  max-width: 100vw;
  position: relative;

  .title {
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 22px;
  }
}

.carousel {
  margin-left: calc(50% - 50vw);
  overflow: hidden;
  width: 100vw;

  .navButton {
    align-items: center;
    align-items: center;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s;
    width: 40px;
    z-index: 2;

    &:hover {
      box-shadow: 0 0px 10px 2px rgb(0 0 0 / 10%);
    }

    svg {
      height: auto;
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .carouselButtonNext {
    right: 73px;

    @extend .navButton;
  }
  .carouselButtonPrev {
    left: 73px;

    @extend .navButton;
    svg {
      transform: rotate(180deg);
    }
  }

  @include media-breakpoint-down(xl) {
    .carouselButtonNext {
      right: 15px;
    }
    .carouselButtonPrev {
      left: 15px;
    }
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100%;
    @include layout();
  }

  :global {
    .swiper-button-disabled {
      svg {
        path {
          fill: $gray;
        }
      }
    }

    .swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          width: auto;
        }
      }
    }
  }
}
