@import '@/styles/styles';

.container {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  height: 400px;
  position: relative;
  width: 670px;

  @include media-breakpoint-down(md) {
    height: 300px;
    width: 350px;
  }

  .box {
    align-items: flex-end;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    filter: drop-shadow(0px 10px 74px rgba(0, 0, 0, 0));
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: all 0.32s ease;
    width: 100%;
  }

  .background {
    background-color: #3c3c3c;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 320ms;
    width: 100%;
    z-index: -1;

    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.67%, #000000 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .content {
    margin-top: auto;
    padding: 30px;
    transition: all 0.32s ease;
    width: 100%;

    .eventType {
      background: $primary;
      border-radius: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      margin-bottom: 24px;
      padding: 3px 12px;
      text-transform: uppercase;
      transition: all 0.32s ease;
      width: fit-content;
    }

    .name {
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 12px;
      transition: all 0.32s ease;
      @include linesLimit(2);
    }

    svg {
      transition: all 0.32s ease;
    }
    .actions {
      column-gap: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: calc(-1 * var(--element-height, 0));
      margin-top: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.32s ease;

      button {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        white-space: nowrap;
      }

      :global {
        button {
          &.btn-primary,
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  &:hover {
    z-index: 1;

    .background {
      height: var(--hover-height, 0);
    }

    .box {
      filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.3));
      height: 120%;
      left: -10%;
      top: -10%;
      width: 120%;
    }

    .content {
      .name {
        color: #000000;
      }
      svg {
        color: #edc21e;
      }
      .actions {
        margin-bottom: 0;
        margin-top: 23px;
        opacity: 1;
      }
    }
  }
}
