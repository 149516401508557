@import '@/styles/styles';

.container {
  align-items: center;
  background: $gray;
  border-radius: 10px;
  column-gap: 30px;
  display: grid;
  grid-template-columns: 100px auto;
  max-width: 370px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: 100%;
}

.image {
  background-color: #3c3c3c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 100px;
  width: 100px;
}

.content {
  .name {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 15px;
    @include linesLimit(2);
  }
}
