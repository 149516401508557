@import '@/styles/styles';

.container {
  position: relative;
}

.content {
  padding: 58px 0 70px 0;
}

.background {
  @include fullWidth();
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
}
